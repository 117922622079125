<template>
    <record-page :table="table">
        <span style="margin-left: 15px;" class="common-table__title-item"
              :class="{active: table === 'baseArticleLiterature/queryBaseArticleLiteraturePage'}" @click="changeTab('baseArticleLiterature/queryBaseArticleLiteraturePage')">临床文献</span>
        <span class="common-table__title-item" :class="{active: table === 'baseArticleGuide/queryBaseArticleGuidePage'}" @click="changeTab('baseArticleGuide/queryBaseArticleGuidePage')">临床指南与共识</span>
        <span class="common-table__title-item" :class="{active: table === 'baseArticlePath/queryBaseArticlePathPage'}" @click="changeTab('baseArticlePath/queryBaseArticlePathPage')" >临床路径</span>
    </record-page>
</template>

<script>
    import RecordPage from './RecordList'
    export default {
        components: {RecordPage},
        data() {
            return {
                table: ''
            }
        },
        created() {
            this.changeTab('baseArticleLiterature/queryBaseArticleLiteraturePage')
        },
        methods: {
            changeTab(table) {
                if (this.table !== table) {
                    this.table = table
                }
            }
        },
    }
</script>